<template>
  <div class="contact">
    <router-link to="/">
      <h1 class="title">
        <img src="logo.png" alt="Skultes Gym" />
      </h1>
    </router-link>
    <div class="card">
      <div class="list">
        <div class="list__item">
          <h4 class="list__item__subtitle">Darba laiks</h4>
          <div class="list__item__content">
            <span> Pirmdiena - Svētdiena </span>
            <span> 6:30 - 24:00 </span>
          </div>
        </div>
        <div class="list__item">
          <h4 class="list__item__subtitle">Kontakti</h4>
          <div class="list__item__content">
            <span> Jānis </span>
            <span>
              <a href="tel:+37129241681">29 241 681</a>
            </span>
          </div>
          <div class="list__item__content">
            <span> Atis </span>
            <span>
              <a href="tel:+37127804609">27 804 609</a>
            </span>
          </div>
          <div class="list__item__content">
            <span> Līga </span>
            <span>
              <a href="tel:+37129180747">29 180 747</a>
            </span>
          </div>
        </div>
        <div class="list__item">
          <h4 class="list__item__subtitle">Adrese</h4>
          <div class="list__item__content">
            <span> Skultes, Tumes pagasts </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200&display=swap');
@import '../components/colors';
@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.contact {
  font-family: 'Poppins', 'sans-serif';

  a {
    text-decoration: none;
    color: unset;
  }
}

.title {
  img {
    height: auto;
    width: 100%;
    max-width: 220px;
  }
}

.card {
  background-color: $color4;
  color: $color1;
  width: calc(100% - 20px);
  max-width: 380px;
  border-radius: 15px;
  padding: 10px;

  h3 {
    font-size: 30px;
  }

  .list {
    &__item {
      a {
        color: $color1;
      }

      &__subtitle {
        font-size: 22px;
        margin: 0px 5px 0px 5px;
        padding: 5px;
        text-align: left;
      }

      &__content {
        display: flex;
        justify-content: space-between;
        margin: 5px 10px;

        span {
          &:nth-child(2) {
            font-weight: 600;
          }
        }
      }
    }
  }
}
</style>
