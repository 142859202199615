<template>
  <div class="popup">
    <p><span>Rezervēt iespējams mēnesi uz priekšu!</span></p>
    <p>
      Pēc liela pieprasījuma, mēs esam izveidojuši iespēju rezervēt treniņus
      mēnesi uz priekšu. Paldies, ka trenējaties pie mums!
    </p>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss">
@import 'colors.scss';

.popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  font-size: 17px;
  background-color: $color2;
  color: $color3;
  position: absolute;
  top: 0;
  left: 0;
  font-weight: normal;
  p {
    padding: 3px;
    margin: 0;
    span {
      font-weight: 600;
    }

    &:first-child {
      margin-bottom: 3px;
    }
  }
}
</style>
