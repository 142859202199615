<template>
  <div class="spinner">
    <div class="loadingio-spinner-rolling-m1e8ui1q0jb">
      <div class="ldio-ood1y432iu">
        <div></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'LoadingSpinner',
};
</script>
<style scoped lang="scss">
@import 'colors.scss';
.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes ldio-ood1y432iu {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
.ldio-ood1y432iu div {
  position: absolute;
  width: 58px;
  height: 58px;
  border: 20px solid $color2;
  border-top-color: transparent;
  border-radius: 50%;
}
.ldio-ood1y432iu div {
  animation: ldio-ood1y432iu 1s linear infinite;
  top: 50px;
  left: 50px;
}
.loadingio-spinner-rolling-m1e8ui1q0jb {
  width: 50px;
  height: 50px;
  display: inline-block;
  overflow: hidden;
  background: $color3;
}
.ldio-ood1y432iu {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.5);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-ood1y432iu div {
  box-sizing: content-box;
}
</style>
