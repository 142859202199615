<template>
  <div class="wrapper">
    <h1 class="title">404</h1>
    <p>Lapa nav atrasta</p>
  </div>
</template>

<style scoped lang="scss">
.wrapper {
  height: 100%;
  padding-top: 50%;
}
.title {
  font-family: 'Poppins', sans-serif;
  text-transform: uppercase;
  font-size: 80px;
  line-height: 60px;
  font-weight: 700;
  margin: 0 auto;
  width: 100%;
  max-width: 400px;
}
</style>
