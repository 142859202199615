<template>
  <div class="home">
    <PopupComponent />
    <h1 class="title">
      <img src="logo.png" alt="Skultes Gym" />
    </h1>
    <LoadingSpinner
      v-if="$store.getters.loadingStatus && !$store.getters.availability.length"
    />
    <div v-else>
      <button class="btn-rounded-clear" @click="scrollDown">Pieteikties</button>

      <AvailabilityComponent />
      <InputForm />
      <CartComponent v-if="$store.getters.cart.length > 0" />
      <span ref="bottom"></span>
      <footer>
        <router-link to="/terms"> Privātuma politika </router-link>
        <router-link to="/terms"> Naudas atgriešanas noteikumi </router-link>
        <router-link to="/terms"> Lietošanas noteikumi </router-link>
        <router-link to="/contact"> Kontakti </router-link>
      </footer>
    </div>
  </div>
</template>

<script>
import CartComponent from '../components/CartComponent.vue';
import LoadingSpinner from '../components/LoadingSpinner.vue';
import InputForm from '../components/InputForm.vue';
import AvailabilityComponent from '../components/AvailabilityComponent.vue';
import PopupComponent from '../components/PopupComponent.vue';

export default {
  name: 'HomeView',
  components: {
    CartComponent,
    InputForm,
    LoadingSpinner,
    AvailabilityComponent,
    PopupComponent,
  },
  data() {
    return {};
  },
  computed: {
    startingDate() {
      const today = new Date();
      const currentDate = new Date(today);
      currentDate.setDate(today.getDate());
      const dateValue = currentDate.toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      });
      const [month, day, year] = dateValue.split('/');
      const dateVal = parseInt(year + month + day, 10);
      return dateVal;
    },
  },
  mounted() {
    this.$store.dispatch('fetchAvailability');
  },
  methods: {
    scrollDown() {
      const el = this.$refs.bottom;

      if (el) {
        el.scrollIntoView({ behavior: 'smooth' });
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100..900&display=swap');
@import '../components/colors';
@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.home {
  padding-top: 30vh;
}

.title {
  img {
    height: auto;
    width: 100%;
    max-width: 320px;
  }
}

.btn-rounded-clear {
  margin-bottom: 300px;
  font-size: 22px;
  background-color: transparent;
  font-weight: 400;
  color: $color2;
  border: 2px solid $color2;
  padding: 10px 10px 10px 16px;
  width: 200px;
  font-family: 'Poppins', sans-serif;
  border-radius: 15px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    text-underline-offset: 4px;
  }
}

footer {
  margin-top: 50px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  text-align: left;

  a {
    text-decoration: none;
    color: $color2;
    font-size: 14px;
    margin-bottom: 5px;
  }
}
</style>
