<template>
  <div class="success" :class="{ hidden: !status }">
    <LoadingSpinner v-if="!status" />
    <div v-else class="card">
      <router-link to="/">
        <h1 class="title">skultes <br /><span>gym</span></h1>
      </router-link>
      <div v-if="status === 'cancelled'" class="info">
        <h4 class="reserved-title">Apmeklējums ir atcelts!</h4>
        <div v-if="data.coupon && data.price">
          <p>Paldies, ka atcēlāt apmeklējumu laicīgi!</p>
          <p>
            Kupona kods &euro;{{ (data.price / 100).toFixed(2) }} vērtībā:
            {{ data.coupon }}
          </p>
        </div>
        <div v-else>
          <p>
            Apmeklējums atcelts, taču mazāk kā 24 stundas pirms apmeklējuma
            laika.
          </p>
          <p>Kupona kods netiek piedāvāts</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingSpinner from '../components/LoadingSpinner.vue';

export default {
  name: 'CancellationView',
  components: {
    LoadingSpinner,
  },
  data() {
    return {
      status: null,
      data: null,
      id: null,
    };
  },
  mounted() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      fetch(`${process.env.VUE_APP_HOST_URL}/api/availability/delete`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id: this.id }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((data) => {
          this.status = 'cancelled';
          this.data = data;
        })
        .catch((error) => {
          console.error('Cancellation failed:', error);
        });
    } else {
      this.status = 'unknown';
    }
  },
  methods: {},
};
</script>

<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200&display=swap');
@import '../components/colors';
@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.success {
  font-family: 'Poppins', 'sans-serif';

  &.hidden {
    padding-top: 20vh;
  }

  a {
    text-decoration: none;
    color: unset;
  }
}

.card {
  background-color: $color4;
  color: $color1;
  padding-top: 30px;

  .title {
    font-family: 'Jost', sans-serif;
    text-transform: uppercase;
    font-size: 40px;
    line-height: 30px;
    font-style: italic;
    font-weight: 700;
    margin: 0 auto;
    width: 400px;

    span {
      font-size: 40px;
      line-height: 28px;
    }
  }

  a {
    color: $color1;
  }

  .reserved-title {
    font-size: 22px;
    margin: 0px 5px 0px 5px;
    padding: 5px;
  }
}

.info {
  padding: 30px 10px;
}
</style>
